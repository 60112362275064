import * as Yup from 'yup';

type EnumName = 'parent' | 'internal' | 'external' | 'url';

const menuValidation = Yup.object().shape({
  label: Yup.string().required('Name is required'),
  url_id: Yup.string().nullable(),
  scroll_target: Yup.string().nullable(),
  parent_id: Yup.string().nullable(),
  type: Yup.mixed<EnumName>().oneOf(['parent', 'internal', 'external', 'url']),
  external_url: Yup.string().nullable(),
  internal_url: Yup.string().nullable(),
  target_blank: Yup.boolean(),
});

export default menuValidation;
