import { MenuItem } from '@mui/material';
import { ChangeEvent } from 'react';
import { AnyObject } from 'yup';
import TextFieldWrapper from '../TextField/TextField';

interface DropDownInterface {
  name: string;
  strictType?: string;
  arrayData?: any;
  onChange: (value: string) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
  fieldValue?: string;
  label: string;
}

function DropdownGenerator({
  name,
  fieldValue = '',
  label,
  strictType = 'Forgotten strictType',
  arrayData = [],
  onChange,
  onBlur,
}: DropDownInterface) {
  return (
    <TextFieldWrapper
      select
      key={name}
      name={name}
      label={label}
      onChange={(e: ChangeEvent<any>) => {
        onChange(e.target.value);
      }}
      onBlur={onBlur}
      value={fieldValue ?? ''}
    >
      {strictType === 'url' && (
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
      )}

      {arrayData[strictType]?.map((item: any) => {
        switch (strictType) {
          case 'url':
            return (
              <MenuItem key={item.id} value={item.id}>
                {`/${item.url} (${item.name} - ${item.page_category})`}
              </MenuItem>
            );
          case 'menu_type':
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          case 'user_types':
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          default:
            return (
              <MenuItem key={item.text} value={item.value}>
                {item.text}
              </MenuItem>
            );
        }
      })}
    </TextFieldWrapper>
  );
}

export default DropdownGenerator;
