import { FormControlLabel, Switch } from '@mui/material';
import { ChangeEvent } from 'react';

interface SwitchWrapperProps {
  name: string;
  label: string;
  onChange: (isChecked: boolean) => void;
  value: boolean;
}

function SwitchWrapper({ name, label, onChange, value }: SwitchWrapperProps) {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          onChange={(e, checked) => onChange(checked)}
          name={name}
        />
      }
      label={label}
    />
  );
}

export default SwitchWrapper;
